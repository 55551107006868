<template>
  <div class="rcc-table-list">
    <div class="rcc-table-list__button-wrapper" v-if="editAccess">
      <rcc-button @click="$router.push({ name: 'regions-new' })">
        Добавить регион
      </rcc-button>
    </div>

    <div class="rcc-table-list__table-wrapper">
      <rcc-table
        :page.sync="page"
        :pageCount="pagesCount"
        :itemsPerPage="rowsOnTable"
        :headers="headers"
        :rows="rows"
        :is-fixed-header="true"
        :isLoading="isDataLoading"
        @filters-changed="handleFiltersChanged"
      />
    </div>
  </div>
</template>

<script>
import Page from '@/mixins/page'
import TableList from '@/mixins/table-list'
import RegionsApi from '@/api/regions'
import headerData from './header'

import RccButton from 'Components/controls/rcc-button'
import RccTable from 'Components/table/rcc-table'

export default {
  mixins: [Page, TableList, RegionsApi],

  components: {
    RccTable,
    RccButton
  },

  computed: {
    headerData() {
      return headerData
    }
  },

  methods: {
    loadData(filters) {
      this.isDataLoading = true

      this.getRegions({ page: this.page, per_page: this.rowsOnTable, ...filters })
        .then(({ pages, items }) => {
          if (!pages) {
            this.pagesCount = 1
            this.rows = []
            return
          }

          this.pagesCount = pages
          this.rows = items
        })
        .finally(() => { this.isDataLoading = false })
    }
  }
}
</script>
